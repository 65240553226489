<template>
    <div class="item pointer-disabled">
        <div class="qb__choice-wrapper">
            <div class="qb__choice">
                <b-row>
                    <b-col cols="1" class="qb__choice-drag">
                        <i class="fa fa-ellipsis-h qb__choice-drag-icon" />
                        <i class="fa fa-ellipsis-h qb__choice-drag-icon" />
                    </b-col>
                    <b-col>
                        <div>
                            <span v-b-tooltip.hover title="Remove" class="qb__question-remove-icon-wrapper">
                                <i class="fa fa-remove qb__question-remove-icon pointer-action" @click="removeChoice" />
                            </span>
                        </div>

                        <wit-input-group
                            v-model="$v.choice.label.$model"
                            type="text"
                            input-label="Label"
                            placeholder="Label"
                            @input="emitInput"
                            :label-col="3"
                            :input-col="9"
                            :model="$v.choice.label"
                            invalid="This field is required"
                            valid="Label is valid"
                        />

                        <wit-input-group
                            v-model="$v.choice.name.$model"
                            type="text"
                            input-label="Name"
                            placeholder="Name (optional)"
                            @input="emitInput"
                            :label-col="3"
                            :input-col="9"
                            :model="$v.choice.name"
                            invalid="This field is required"
                            valid="Name is valid"
                        />

                        <wit-input-group
                            v-model="$v.choice.value.$model"
                            type="textarea"
                            input-label="Value"
                            placeholder="Value"
                            @input="emitInput"
                            :label-col="3"
                            :input-col="9"
                            :model="$v.choice.value"
                            invalid="This field is required"
                            valid="Value is valid"
                            :rows="1"
                        />

                        <wit-input-group
                            v-model="$v.choice.nestedQuestions.$model"
                            type="checkbox"
                            input-label="Nested questions"
                            @input="emitInput"
                            :label-col="3"
                            :input-col="9"
                            checked="On"
                            unchecked="Off"
                        />

                        <question-builder
                            :ref="`qb_${choice.uuid}`"
                            v-if="choice.nestedQuestions"
                            v-model="choice.questions"
                            :valid.sync="questionsValid"
                            @update:valid="emitUpdate"
                            @input="emitInput"
                        ></question-builder>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'

const isEqual = require('fast-deep-equal')

export default {
    components: {
        QuestionBuilder: () => import('./QuestionBuilder.vue'),
        WitInputGroup,
    },
    props: {
        value: {
            required: true,
        },
        valid: {
            type: Boolean,
        },
    },
    mixins: [validationMixin],
    validations: {
        choice: {
            uuid: {},
            label: {required},
            name: {},
            value: {},
            nestedQuestions: {},
            questions: {
                valid: function(_, value) {
                    return value.nestedQuestions ? value.questions.length > 0 && this.questionsValid : true
                },
            },
        },
    },
    data() {
        return {
            choice: {
                uuid: '',
                label: '',
                name: '',
                value: '',
                nestedQuestions: false,
                questions: [],
            },
            questionsValid: false,
        }
    },
    watch: {
        value() {
            if (!isEqual(this.choice, this.value)) {
                this.choice = {...this.choice, ...this.value}
            }
        },
    },
    created() {
        this.choice = {...this.choice, ...this.value}
        this.emitUpdate()
    },
    methods: {
        removeChoice() {
            this.$emit('remove', {...this.choice})
        },

        emitUpdate() {
            this.$emit('update:valid', !this.$v.$invalid)
        },

        emitInput() {
            this.$emit('input', this.choice)
            this.emitUpdate()
        },

        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
</script>

<style></style>
